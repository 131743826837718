import React, { Component } from 'react';
import { If } from 'react-if';
import { Navigate } from 'react-router';
import authService from 'src/services/auth.service';

export default class CheckAuthen extends Component {
  

  render() {
    
	const isAuthen = authService.isLogin();
	console.log(isAuthen);
    return (
      <div>
        <If condition={isAuthen == false}> 
		{console.log(isAuthen)}
          <Navigate to="/login" />
        </If>
      </div>
    );
  }
}
