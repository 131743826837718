import axios from 'axios';
import CryptoJS from 'crypto-js';

import jwt_decode from 'jwt-decode';
import Constants from 'src/config/Constants';
import API from 'src/utils/API';
import authHeader from './auth-header';

const userData = 'userData';

class AuthService {
  refreshToken() {
    let token = this.getCurrent();
    const accessToken = token.accessToken;
    const url = 'Auth/refresh_token/' + accessToken;
    //console.log(url);
    return API.get(url, { headers: authHeader() }).then((response) => {
      //	console.log(response.data);
      if (response.data.status === Constants.JSON_STATUS_SUCCESS) {
        const userdata = {
          staffname: response.data.staffname,
          userToken: response.data.userToken,
          accessToken: response.data.accessToken,
          refreshToken: response.data.refreshToken
        };
        localStorage.setItem(userData, JSON.stringify(userdata));
      } else {
        localStorage.removeItem(userData);
      }
    });
  }

  async login(username, password) {
    const param = {
      username,
      password
    };
    /*
    var encrypt_param = CryptoJS.AES.encrypt(
      JSON.stringify(param),
      Constants.ENCRYPT_SECERT
    ).toString();

	const paramdata={'param':encrypt_param}

	*/
    // console.log(encrypt_param);
    return API.post('Auth/login', param)
      .then((response) => {
		console.log(response)
        if (response.data.status === Constants.JSON_STATUS_SUCCESS) {
          const userdata = {
            staffname: response.data.staffname,
            userToken: response.data.userToken,
            accessToken: response.data.accessToken,
            refreshToken: response.data.refreshToken
          };
          localStorage.setItem(userData, JSON.stringify(userdata));
        } else {
          localStorage.removeItem(userData);
        }
        // console.log(localStorage);
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updateProfile(param) {
    return API.post('v1/User/updateProfile', param, {
      headers: authHeader()
    }).then((response) => {
      //	console.log(response.data);
      return response.data;
    });
  }

  logout() {
    console.log('####### logout #########');
    localStorage.removeItem(userData);
  }

  getProfile() {
    return API.post('v1/User/profile', {}, { headers: authHeader() }).then(
      (response) => {
        //	console.log(response.data);
        return response.data;
      }
    );
  }

  logout() {
    console.log('####### logout #########');
    localStorage.removeItem(userData);
  }

  register(username, email, password) {
    return axios.post('/signup', {
      username,
      email,
      password
    });
  }

  getCurrent() {
    // console.log(localStorage.getItem(userData));
    const tokenData = localStorage.getItem(userData);
    //console.log('getCurrent=='+tokenData);
    //return userDataS;

    if (tokenData) {
      const tokenData_json = JSON.parse(tokenData);
      let decodedToken = jwt_decode(tokenData_json.userToken);
      decodedToken.accessToken = tokenData_json.accessToken;
      //console.log('cccccccccc='+decodedToken);
      return decodedToken;
    } else {
      return {
        staffname: null,
        fname: null,
        lname: null,
        user_id: null,
        email: null,
        mobile_phone: null,
        accessToken: null
      };
    }
  }

  getJWTData() {
    // console.log(localStorage.getItem(userData));
    const jwtData = JSON.parse(localStorage.getItem(userData));

    if (jwtData) {
      const decodedToken = jwt_decode(tokenData.userToken);
      return decodedToken;
    } else {
      return null;
    }
  }

  isLogin() {
    // console.log(localStorage.getItem(userData));
    const tokenData = localStorage.getItem(userData);
    if (tokenData) {
      const tokenData_ = JSON.parse(tokenData);
      //console.log(tokenData_.userToken);
      try {
        const decodedToken = jwt_decode(tokenData_.userToken);

        const currentDate = new Date();
        if (decodedToken.issuedAt * 1000 < currentDate.getTime()) {
          return false;
        } else {
          return true;
        }
      } catch (e) {
        console.log(e);
        localStorage.removeItem(userData);
        return false;
      }
    } else {
      return false;
    }
  }
}

export default new AuthService();
