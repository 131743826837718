import axios from 'axios';
import React, { Component } from 'react';

class CheckInternet extends Component {
	 
	render() {
		axios.interceptors.response.use(
			response => {
				return response
			},
			error => {
				if (!error.response) {
					console.log("Please check your internet connection.");
				}
		
				return Promise.reject(error)
			}
		)

		return (
			<div>
				
			</div>
		);
	}
}

export default CheckInternet;