import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
});

export default function SettingTab(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.index);

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="บัญชีผู้ใช้งาน" href="account"  />
        <Tab label="การแจ้งเตือน" href="settings"   />
        <Tab label="เปลี่ยนรหัสผ่าน"  href="changepassword" />
      </Tabs>
    </Paper>
  );
}
