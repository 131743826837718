import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import authService from '../services/auth.service';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import { Toast } from 'primereact/toast';

import { JSON_STATUS_SUCCESS } from 'src/config/Constants';
import React, { useEffect, useRef, useState } from 'react';
import CheckInternet from 'src/components/checkinternet/CheckInternet';


const Login = () => {
  const navigate = useNavigate();
  const toast = useRef(null);

  const submitForm = (values, v) => {
   // console.log(v.isSubmitting);
      authService.login(values.email, values.password).then(
      (resp) => {
		  console.log(resp);
        // eslint-disable-next-line
        if (resp.status == JSON_STATUS_SUCCESS) {
          //this.props.history.push("/Dashboard");
          //window.location.reload();
          navigate('/app/dashboard', { replace: true });
        } else {
          //   console.log(v);
          v.setSubmitting(false);
          //console.log(isSubmitting);
          toast.current.show({
            severity: 'error',
            summary: resp.message,
            life: 3000
          });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  return (
    <>
      <Helmet>
        <title>เข้าระบบ</title>
      </Helmet>
      <Toast ref={toast} />
	  <CheckInternet />
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="xs">
          <Card>
            <CardContent>
              <Formik
                initialValues={{
                  email: '',
                  password: ''
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email('Must be a valid email')
                    .max(255)
                    .required('Email is required'),
                  password: Yup.string()
                    .max(255)
                    .required('Password is required')
                })}
                onSubmit={(values, v) => {
                  submitForm(values, v);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box sx={{ mb: 3 }}>
                      <Typography color="textPrimary" variant="h2">
                        เข้าระบบ
                      </Typography>
                    </Box>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email Address"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Password"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />
                    <Box sx={{ py: 2 }}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        เข้าระบบ
                      </Button>
                    </Box>
                    <Typography color="textSecondary" variant="body1">
                      Don&apos;t have an account?{' '}
                      <Link component={RouterLink} to="/register" variant="h6">
                        Sign up
                      </Link>
                    </Typography>
                  </form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
