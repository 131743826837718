import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  TextField
} from '@material-ui/core';
import authService from 'src/services/auth.service';
import { JSON_STATUS_SUCCESS } from 'src/config/Constants';
import { Toast } from 'primereact/toast';
import { Formik } from 'formik';
import * as Yup from 'yup';
import SettingTab from '../settings/SettingTab';

const states = [
  {
    value: 'alabama',
    label: 'Alabama'
  },
  {
    value: 'new-york',
    label: 'New York'
  },
  {
    value: 'san-francisco',
    label: 'San Francisco'
  }
];

const AccountProfileDetails = (props) => {
  const [btnDisable, setBtnDisable] = useState(true);
  const [formValues, setFormValues] = useState({
    fname: '',
    lname: '',
    email: '',
    mobile_phone: ''
  });

  const toast = useRef(null);

  //console.log(isSubmitting);
  const [loading, setLoading] = useState('block');
  const [loadingForm, setLoadingForm] = useState('none');

  const submitForm = (values, v) => {
    console.log(v.isSubmitting);
    setLoading('block');
    const param = {
      fname: values.fname,
      lname: values.lname,
      email: values.email,
      mobile_phone: values.mobile_phone
    };
    authService.updateProfile(param).then((response) => {
      // console.log(response.status);
      if (response.status == JSON_STATUS_SUCCESS) {
		authService.refreshToken();
        toast.current.show({
          severity: 'success',
          summary: response.message,
          life: 3000
        });
      } else {
        toast.current.show({
          severity: 'error',
          summary: response.message,
          life: 3000
        });
      }
      setLoading('none');
    });
  };

  useEffect(() => {
    authService.getProfile().then((response) => {
      // console.log(response.status);
      if (response.status == JSON_STATUS_SUCCESS) {
        setFormValues({
          fname: response.record.fname,
          lname: response.record.lname,
          email: response.record.email,
          mobile_phone: response.record.mobile_phone
        });
        setBtnDisable(false);
        setLoading('none');
		setLoadingForm('block');
      }
    });
  }, []);

  //console.log(formValues);

  return (
    <div>
      <Toast ref={toast} />
      <Box component="div" display={loading}>
        <CircularProgress />
      </Box>
      <Formik
        initialValues={formValues}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required'),
          fname: Yup.string().max(255).required('กรุณากรอก'),
          lname: Yup.string().max(255).required('กรุณากรอก'),
          mobile_phone: Yup.string()
            .max(10)
            .required('กรุณากรอกเบอร์มือถือของท่าน')
        })}
        onSubmit={(values, v) => {
          console.log('ccccc');
          submitForm(values, v);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
			
            <Box component="div" display={loadingForm}>
              <Card>
                <CardHeader
                  subheader="สามารถปรับปรุงข้อมูลส่วนตัวได้"
                  title="บัญชีผู้ใช้งาน"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.fname && errors.fname)}
                        fullWidth
                        helperText={touched.fname && errors.fname}
                        label="ชื่อ"
                        name="fname"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.fname}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.lname && errors.lname)}
                        helperText={touched.lname && errors.lname}
                        fullWidth
                        label="Last name"
                        name="lname"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lname}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Email Address"
                        name="email"
                        disabled={true}
                        onChange={handleChange}
                        required
                        value={values.email}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Phone Number"
                        name="phone"
                        disabled={true}
                        onChange={handleChange}
                        type="number"
                        value={values.mobile_phone}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Country"
                        name="country"
                        onChange={handleChange}
                        required
                        value={values.country}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Select State"
                        name="state"
                        onChange={handleChange}
                        select
                        SelectProps={{ native: true }}
                        value={values.state}
                        variant="outlined"
                      >
                        {states.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={btnDisable}
                    type="submit"
                  >
                    บันทึกข้อมูล
                  </Button>
                </Box>
              </Card>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AccountProfileDetails;
