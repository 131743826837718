module.exports = Object.freeze({ 
    APP_VERSION : "1.5.0",
    COPYRIGHT_VERSION : "Copyright © 2021  POSVILLA ,All rights reserved.",
    DATABASE_VERSION : "2021010701",
    DATABASE_CONFIG_FILE :"config_database_connect.json",
    JWT_SECRET:"PBAppPOSVilla231!@312312",
	ENCRYPT_SECERT:"kle6N84M3Gaz2po489Fo",
    LOADING_PAGE_ICON_SIZE:10,
    JSON_STATUS_SUCCESS: 'success',
    JSON_STATUS_ERROR: 'error',
    JSON_NOT_PARAM: 'ส่ง Param ไม่ครบ',
    JSON_FIND_SUCCESS :'พบข้อมูล',
    JSON_CREATE_FILE_ERROR :'ไม่สามารถสร้างไฟล์ได้ เนื่องจากมีไฟล์อยู่แล้ว',
    JSON_CREATE_SUCCESS :'สร้างข้อมูลใหม่เรียบร้อย',
    JSON_CREATE_ERROR :'ไม่สามารถสร้างข้อมูลได้',
    JSON_UPDATE_SUCCESS :'ปรับปรุงข้อมูลเรียบร้อย',

    JSON_STATUS_Unauthorized_CODE : 401,   //ไม่มีสิทธิเข้าใช้งาน
    JSON_STATUS_ERROR_VALIDATE_CODE : 422, // ไม่พบ parameter

    JSON_STATUS_SUCCESS_CODE : 200, // พบข้อมูล ok 200
    JSON_STATUS_CREATE_CODE : 201,  //สร้างข้อมูลใหม่
    JSON_STATUS_SUCCESS_CREATE_CODE : 202 , // เข้า
    JSON_STATUS_SUCCESS_UPDATE_CODE : 202, 
    JSON_STATUS_SUCCESS_DELETE_CODE : 202, 
    
    JSON_STATUS_SERVER_ERROR_CODE : 500, 
    JSON_STATUS_ERROR_CODE : -1, 
 

    CMD_TYPE_CREATE:'create',
    CMD_TYPE_UPDATE:'update'
});