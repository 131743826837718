import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@material-ui/core';
import AccountProfile from 'src/components/account/AccountProfile';
import SettingTab from 'src/components/settings/SettingTab';
import SettingsPassword from 'src/components/settings/SettingsPassword';

const ChangePasswordView = () => (
  <>
    <Helmet>
      <title>เปลี่ยนรหัสผ่าน</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <AccountProfile />
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <SettingTab index={2} />
            <SettingsPassword />
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
);

export default ChangePasswordView;
