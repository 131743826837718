import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { useRoutes } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import './mixins/chartjs';
import theme from './theme';
import routes from './routes';
import authService from './services/auth.service';
import { Else, If } from 'react-if';
import Login from './pages/Login';

const App = () => {
  const routing = useRoutes(routes);

  const isLogin = authService.isLogin();

  if (!isLogin) {
    //console.log('isLogin=='+ isLogin);
  }else{
	//console.log('isLogin 22=='+ isLogin);
  }
  
  //{routing}

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <If condition={!isLogin}>
        <Login />
        <Else>{routing}</Else>
      </If>
    </ThemeProvider>
  );
};

export default App;
